import React, { useContext, useEffect, useState, useCallback } from 'react';
import { round } from "lodash";
import { IndexData } from "../../common/index-context";
import PoweredByBuzzback from '../common/components/poweredByBuzzback';
import ComparisonsIntro from "./comparisonsIntro";
import ComparisonsMenu from "./comparisonsMenu";
import MainInfo from "./mainInfo/index";
import Diagnostics from "./diagnostics";
import StudyDetails from "./studyDetails";
import OpenEndFeedback from "./openEndFeedback/index";
import UnhappyPath from './unhappyPath';
import Benchmarks from './benchmarks';
import { BenchmarksNumbers } from './benchmarksNumbers';
import DeviationsNumbers from './deviationsNumbers';
import ComparisonsFilter from './comparisonsFilter';
import CustomerChallenges from "./customerChallenges";

const Comparisons = () => {
  const { data } = useContext(IndexData);
  const includeDeviation = data.include_deviation;
  const labels = data.labels;
  const [ firstTimeRenderedWithData, setFirstTimeRenderedWithData ] = useState(false);

  const onLastElementRender = useCallback((node) => {
    if (data && node) {
      setFirstTimeRenderedWithData(true);
    }
  }, []);

  useEffect(() => {
    if (firstTimeRenderedWithData) {
      setTimeout(
        () => {
          const id = window.location.hash.replace("#", '');
          const element = document.getElementById(id);
          element?.scrollIntoView({ block: "start", behavior: "smooth" });
        },
        100
      );
    }
  }, [ firstTimeRenderedWithData ]);

  const effortStats = {
    high: {
      percent: round(data?.customer_feedback?.stats?.high || 0), nsize: data?.customer_feedback?.stats?.high_n
    },
    low: {
      percent: round(data?.customer_feedback?.stats?.low || 0), nsize: data?.customer_feedback?.stats?.low_n
    }
  };
  const donutTextEffort = {
    high: `Comments from ${effortStats.high.percent}% (n=${effortStats.high.nsize}) of respondents who say the process takes some to high effort.`,
    low: `Comments from ${effortStats.low.percent}% (n=${effortStats.low.nsize}) of respondents who say the process takes little to no effort.`
  };

  const appealStats = {
    high: {
      percent: round(data?.appeal_open_end?.stats?.high || 0), nsize: data?.appeal_open_end?.stats?.high_n
    },
    low: {
      percent: round(data?.appeal_open_end?.stats?.low || 0), nsize: data?.appeal_open_end?.stats?.low_n
    }
  };
  const donutTextAppeal = {
    high: `Comments from ${appealStats.high.percent}% (n=${appealStats.high.nsize}) of respondents who say the idea is somewhat to extremely appealing.`,
    low: `Comments from ${appealStats.low.percent}% (n=${appealStats.low.nsize}) of respondents who say the idea is not very to not at all appealing.`
  };

  return (
    <div className="content_body">
      <div className={ `comparisons vcc-scorecard ${includeDeviation ? '' : '-no-deviation'}` }>
        <ComparisonsMenu />

        <ComparisonsFilter />

        <ComparisonsIntro />

        <div id="scorecard" className="anchor-point" />
        <MainInfo />

        <div id="customer_challenges" className="anchor-point" />
        <CustomerChallenges />

        <div id="diagnostics" className="anchor-point" />
        <Diagnostics />
        {
          includeDeviation &&
          <>
            <div id="unhappy" className="anchor-point" />
            <UnhappyPath />
          </>
        }
        <div id="process-effort" className="anchor-point" />
        <h2 className="comparisons_section-title">Customer Feedback - Experience Effort</h2>
        {data.customer_feedback &&
          <OpenEndFeedback
            projectId={ data.project_id }
            conceptId={ data.concept_id }
            openEndData={ data.customer_feedback }
            entityTitle="Customer Feedback - Experience Effort"
            titles={ labels.effort.slider_titles }
            donutCaptions={ labels.effort.donut_labels }
            donutTexts={ donutTextEffort }
            questionText={ "How much effort do you think this would take?" }
            slidePrefix={ 'effort' }
          />
        }

        <div id="idea-only" className="anchor-point" />
        <h2 className="comparisons_section-title">Customer Feedback - Idea Only</h2>
        {data.appeal_open_end &&
          <OpenEndFeedback
            projectId={ data.project_id }
            conceptId={ data.concept_id }
            openEndData={ data.appeal_open_end }
            entityTitle="Customer Feedback - Idea Only"
            titles={ labels.appeal.slider_titles }
            donutCaptions={ labels.appeal.donut_labels }
            donutTexts={ donutTextAppeal }
            questionText={ "How appealing do you find this?" }
            slidePrefix={ 'appeal' }
            switchColors
          />
        }

        <div id="study" className="anchor-point" />
        <StudyDetails />

        {!data.arrows_mode && <BenchmarksNumbers ref={ onLastElementRender } />}

        {
          !data.arrows_mode && includeDeviation &&
          <>
            <div id="deviation" className="anchor-point" />
            <DeviationsNumbers />
          </>
        }

        <div id="benchmark" className="anchor-point" />
        <Benchmarks />
        <PoweredByBuzzback />
      </div>
    </div>
  );
};

export default Comparisons;
