import React, { useContext } from 'react';
import classnames from 'classnames';
import Block from './block';
import { IndexData } from './contexts';

const PageViews = () => {
  const { data: { pages } } = useContext(IndexData);

  return (
    <>
      <div className="comparisons_plates">
        <div className="comparisons_section">
          <h2 className="comparisons_section-title -no-margin">
            Page Views
          </h2>
        </div>
        <div className="comparisons_plate-note -black">
          (among VZ account users)
        </div>
      </div>
      <div className="comparisons_plates">
        <div className={ classnames("comparisons_plates-grid pg_blocks", { '-no-hint': false }) }>
          <Block
            className="-page-views"
            header="Total Views"
            value={ pages.total }
            hint="Total number of visits across all pages/views."
          />
          <Block
            className="-page-views"
            header="Database View"
            value={ pages.database }
          />
          <Block
            className="-page-views"
            header="Single-Case View"
            value={ pages.scorecard }
          />
          <Block
            className="-page-views"
            header="Comparison"
            value={ pages.comparison }
          />
          <Block
            className="-page-views"
            header="Subgroup Comparison"
            value={ pages.subgroup_comparison }
          />
          <Block
            className="-page-views"
            header="Executive Snapshot"
            value={ pages.executive_view }
          />
          <Block
            className="-page-views"
            header="Program Health"
            value={ pages.program_health }
          />
        </div>
      </div>
    </>
  );
};

export default PageViews;
