import React from 'react';
import classnames from 'classnames';

const Block = ({ className, header, value, hint }) => (
  <div className={ classnames("comparisons_plates-grid-item pg-block", className) }>
    <div className="comparisons_plate -full-height -flex-vertical -compact">
      <h3 className="comparisons_plate-title -no-transform">{ header }</h3>
      <div className="value-part">
        <hr />
        <div className="comparisons_status -center -black">
          { value }
        </div>
        {
          !!hint &&
          <div className="comparisons_plate-note -margin-top -center -sm">
            { hint }
          </div>
        }
      </div>
    </div>
  </div>
);

export default Block;
