import React, { useContext } from 'react';
import Intro from '../../common/components/naming_intro.js';
import ThoughtBubble from '../../common/components/metrics/thought_bubble.js';
import FivePointScale from '../../common/components/metrics/five_point_scale.js';
import AttributeRatings from '../../common/components/metrics/attribute_ratings.js';
import { defaultScorings } from "../../common/logic/eligible";
import { SurveyContext } from '../../common/contexts';

const Concept = ({
  data, concept, index, conceptIndex, nextStep, monadic, result, forceImage
}) => {
  const { language_code, translations, tb_klass, attribute_ratings } = data;
  const { productDescriptionComponent } = useContext(SurveyContext);

  const scorings = defaultScorings(data.scorings);

  const introText = () => {
    if (monadic) {
      return translations.name_intro_monadic;
    }
    if (!conceptIndex) {
      return translations.name_intro_sequential_first;
    } else if (data.concepts.length - 1 === conceptIndex) {
      return translations.name_intro_sequential_last;
    }
    return translations.name_intro_sequential_next;
  };
  const tb_title = () => (translations.thought_bubble_title.replace('%currentName%', concept.name));
  const maleSelected = () => (result.answers.findIndex("S3_1") > -1);

  return (
    <>
      {
        !index &&
        <Intro
          name={ concept.name }
          text={ introText() }
          nextStep={ () => { nextStep(); } }
          imageUrl={ concept.url }
        />
      }
      {
        index === 1 &&
        <ThoughtBubble
          concept={ concept }
          tbKlass={ tb_klass }
          title={ tb_title() }
          description={ translations.thought_bubble_description }
          showMan={ maleSelected() }
          result={ result }
          nextStep={ () => { nextStep(); } }
        />
      }
      {
        index === 2 &&
        <FivePointScale
          concept={ concept }
          questions={ scorings }
          result={ result }
          nextStep={ () => { nextStep(); } }
          forceImage={ forceImage }
          productDescriptionComponent={ productDescriptionComponent }
        />
      }
      {
        index === 3 &&
        <AttributeRatings
          title={ translations.attribute_ratings_title }
          concept={ concept }
          questions={ attribute_ratings }
          result={ result }
          nextStep={ () => { nextStep(); } }
          languageCode={ language_code }
          forceImage={ forceImage }
          productDescriptionComponent={ productDescriptionComponent }
        />
      }
    </>
  );
};

export default Concept;
