import React from 'react';
import CreatableSelect from 'react-select/creatable';
import { find, includes, lowerCase } from 'lodash';
import { vgSelectStyles } from '../projects/components/helpers';

const SingleSelect = ({ options, selected, onChange, placeholder }) => {
  const value = find(options, (el) => (selected === el.value));

  return (
    <CreatableSelect
      value={ value }
      className="react-select-container"
      classNamePrefix="react-select"
      options={ options }
      onChange={ onChange }
      autosize={ false }
      maxMenuHeight={ 150 }
      styles={ vgSelectStyles }
      placeholder={ placeholder }
      isValidNewOption={ () => (false) }
      filterOption={ (el, q) => { return includes(lowerCase(el.label), lowerCase(q));} }
    />
  );
};

export default SingleSelect;
