import React, { createContext, useContext, useState, useLayoutEffect, useMemo } from 'react';
import { each, find, indexOf, difference, filter, some, map, intersection, keys } from "lodash";
import runPreload from '../logic/preload';
import saveUserData from '../logic/save_user_data';
import PageLoader from '../../common/components/page_loader';
import { fraudContext } from "../../common/contexts";
import {
  completeActionNum,
  LastStepsComponents,
  lastStepsLogic,
  screenedActionNum
} from '../logic/last_steps';
import { screeningIsEligible } from "../../common/logic/eligible";
import { GeneralContext } from '../contexts';
import { screeningBySample } from '../logic/screeningBySample';
import Screening from "./screening";
import Intro from './intro';
import Qualified from "./qualified";
import FivePointScale from "./metrics/five_point_scale";
import QuestionsGroup from './QuestionsGroup';
import PreExposure from './metrics/metricGroups/preExposure';
import Idea from './metrics/metricGroups/idea';
import Process from './metrics/metricGroups/process';
import FullConcept from './metrics/metricGroups/fullConcept';
import DeviationProcess from './metrics/metricGroups/deviationProcess';
import QualifiedDemographics from './metrics/metricGroups/qualifiedDemographics';
import { WithTagsAndAnswersUpdating, indexOfPrefix } from './withTagsAndAnswersUpdating';
import WithCheckQuotas from './withCheckQuotas';

const resultContext = createContext(
  {
    answers: [],
    loops: []
  }
);

const Index = ({ data }) => {
  const [ loading, setLoading ] = useState(true);
  const {
    concepts, demographics, screenings,
    pre_exposures, idea_metrics, process_metrics, full_concept_metrics, deviation_metrics,
    qualified_demographic_metrics, custom_metrics, translations,
    branded, screeners_title_name_matching, sample, paths
  } = data;

  useLayoutEffect(() => {
    runPreload([ ...concepts ], setLoading);
  }, []);

  const [ lastStep ] = useState(13);
  const [ step, setStep ] = useState(1);
  const [ initialMetricGroupStep ] = useState(0);
  const [ metricGroupStep, setMetricGroupStep ] = useState(initialMetricGroupStep);
  const [ groupIndex, setGroupIndex ] = useState(0);
  const [ screeningIndex, setScreeningIndex ] = useState(0);

  const result = useContext(resultContext);
  const { projectId, antiFraud, fraudProcessing } = useContext(fraudContext);

  const questionsGroups = useMemo(() => {
    const result = [];

    each(demographics, (q, i) => {
      if (q.name === "SO19") { //skip Confidentiality Agreement
        return;
      }

      if (q.dedicated) {
        result.push([ q ]);
      } else {
        if (i === 0 || demographics[i - 1].dedicated) {
          result.push([]);
        }
        result[result.length - 1].push(q);
      }

      if (q.name === "SO94") {
        result.push([
          {
            name: "Intro",
            text: translations.intro_middle_text
          }
        ]);
      }
    });

    return result;
  }, [ demographics ]);

  const terminate = (questions, answers) => {
    const eligibles = map(questions, (question) => (screeningIsEligible(question, answers)));
    const notEligible = some(eligibles, (el) => !el);
    return notEligible;
  };

  const fiveOperators = (answers) => {
    return intersection(answers, [ "SO12_2", "SO12_14", "SO12_17", "SO12_18", "SO12_-2" ]).length > 0;
  };

  const skippedQuestions = (answers) => {
    const skipped = [];

    if (indexOf(answers, "SO91_1") === -1) {
      skipped.push("SO92");
    }
    if (indexOf(answers, "SO93_1") === -1) {
      skipped.push("SO94");
    }

    if (indexOf(answers, "SO10_1") === -1) {
      skipped.push("SO11");
      skipped.push("SO12");
      skipped.push("SO13");
      skipped.push("SO14");
      skipped.push("SO15");
    }

    if (indexOfPrefix(answers, "SO12_") > -1 && !fiveOperators(answers)) {
      skipped.push("SO13");
    }

    if (indexOfPrefix(answers, "SO12_") > -1) {
      const fo = fiveOperators(answers);
      if (
        !fo ||
        (fo && (indexOf(answers, "SO13_1") > -1 || indexOf(answers, "SO13_3") > -1))
      ) {
        skipped.push("SO14");
      }
    }

    if (indexOf(answers, "SO10_2") === -1) {
      skipped.push("SO16");
      skipped.push("SO17");
      skipped.push("SO18");
    }

    return skipped;
  };

  const nextGroupIndex = (groupIndex) => {
    let nextIndex = groupIndex;

    while (nextIndex < questionsGroups.length - 1) {
      nextIndex = nextIndex + 1;

      const groupQNames = questionsGroups[nextIndex].map((q) => (q.name));
      const skippedQNames = skippedQuestions(result.answers);

      if (difference(groupQNames, skippedQNames).length > 0) {
        return nextIndex;
      }
    }

    return -1;
  };

  const filteredGroup = (index) => {
    const skippedQNames = skippedQuestions(result.answers);
    return filter(questionsGroups[index], (q) => {
      return indexOf(skippedQNames, q.name) === -1;
    });
  };

  const inLongMOList = (answers) => {
    const MOs = [ "SO12_1", "SO12_3", "SO12_4", "SO12_5", "SO12_6", "SO12_7", "SO12_8", "SO12_9", "SO12_10", "SO12_11",
      "SO12_13", "SO12_15", "SO12_16", "SO12_19", "SO12_20", "SO12_21" ];
    return intersection(answers, MOs).length > 0;
  };

  const inShortMOList = (answers) => {
    return intersection(answers, [ "SO12_12", "SO12_22" ]).length > 0;
  };

  const autoPunch = (answers) => {
    if (indexOfPrefix(answers, "SO12_") > -1) {
      // If QPREPAIDPLAN = 1, code as QPREPAID = 2
      // If WIRELESS PHONE SERVICE USE = Spectrum Mobile, Xfinity, code as QPREPAID = 2
      if (indexOf(answers, "SO14_2") === -1 && (indexOf(answers, "SO13_1") > -1 || inShortMOList(answers))) {
        answers.push("SO14_2");
      }

      // If WIRELESS PHONE SERVICE USE = ......, code as QPREPAID = 1
      if (indexOf(answers, "SO14_1") === -1 && inLongMOList(answers)) {
        answers.push("SO14_1");
      }
    }
  };

  const checkScreeningBySampleWithAction = (currentGroup, nextConfidentialAgreementQuestion) => (
    {
      check: () => screeningBySample(
        sample, currentGroup, nextConfidentialAgreementQuestion, result, screeners_title_name_matching, branded
      ),
      action: () => {
        setStep(lastStep);
        goForwardLast(screenedActionNum); //not_eligible
      }
    }
  );

  const screenerCheckBeforeConfidential = (callback) => {
    const { check, action } = checkScreeningBySampleWithAction(null, true);
    if (check()) {
      action();
    } else {
      callback();
    }
  };

  const step2Logic = () => {
    autoPunch(result.answers);

    const currentGroup = filteredGroup(groupIndex);
    if (terminate(currentGroup, result.answers)) {
      setStep(lastStep);
      goForwardLast(screenedActionNum); //not_eligible
      return;
    }

    const index = nextGroupIndex(groupIndex);
    const { check, action } = checkScreeningBySampleWithAction(currentGroup, false);
    if (check()) {
      action();
      return;
    }

    if (index > -1) {
      setGroupIndex(index);
    } else if (screenings.length) {
      setStep(3);
    } else {
      screenerCheckBeforeConfidential(() => setStep(4));
    }
  };

  const step3Logic = () => {
    const eligible = screeningIsEligible(screenings[screeningIndex], result.answers);

    if (!eligible) {
      setStep(lastStep);
      goForwardLast(screenedActionNum); //not_eligible
      return;
    }

    if (screeningIndex < screenings.length - 1) {
      setScreeningIndex(screeningIndex + 1);
    } else {
      screenerCheckBeforeConfidential(() => setStep(4));
    }
  };

  const step4Logic = () => {
    if (terminate([ find(demographics, (el) => el.name === 'SO19') ], result.answers)) {
      setStep(lastStep);
      goForwardLast(screenedActionNum); //not_eligible
      return;
    }
    setStep(5);
  };

  const goForward = () => {
    if (step === 1) {
      // Intro
      setStep(2);
    } else if (step === 2) {
      // Demographics
      step2Logic();
    } else if (step === 3) {
      // Screening
      step3Logic();
    } else if (step === 4) {
      // Confidentiality Agreement
      step4Logic();
    } else {
      // Check Qualified metrics and demographics
      const afterStepsCheck = {
        5: {
          check: () => (pre_exposures.length),
          action: () => {
            setStep(6);
          }
        },
        6: {
          check: () => (idea_metrics.length),
          action: () => {
            setStep(7);
          }
        },
        7: {
          check: () => (process_metrics.length),
          action: () => {
            setStep(8);
          }
        },
        8: {
          check: () => (full_concept_metrics.length),
          action: () => {
            setStep(9);
          }
        },
        9: {
          check: () => (deviation_metrics.length),
          action: () => {
            setStep(10);
          }
        },
        10: {
          check: () => (custom_metrics.length),
          action: () => {
            setStep(11);
          }
        },
        11: {
          check: () => (qualified_demographic_metrics.length),
          action: () => {
            setStep(12);
          }
        },
        12: {
          check: () => true,
          action: () => {
            setStep(lastStep);
            goForwardLast(lastStep);
          }
        }
      };
      let i = step;
      const afterStepsCheckKeys = keys(afterStepsCheck);
      const lastProcessItem = afterStepsCheckKeys[afterStepsCheckKeys.length - 1];
      if (i > lastProcessItem) {
        goForwardLast(completeActionNum);
      } else {
        while (!afterStepsCheck[i].check()) {
          i += 1;
        }
        afterStepsCheck[i].action();
      }
    }
  };

  const instance = {
    updateStep: (newStep) => {
      setStep(newStep);
    },
    forward: (v) => {
      goForwardLast(v + 1);
    },
    runSave: (status) => {
      saveUserData(data.save_url, status, result, data);
    }
  };

  const goForwardLast = (lStep) => {
    const [ newStep, forward ] = lastStepsLogic(instance, lStep, true, lastStep, data, result, projectId, antiFraud);
    if (newStep) {
      instance.updateStep(newStep + (forward ? 1 : 0));
    }
  };

  const generalContextValue = {
    goForward, data, branded, demographics, filteredGroup,
    concept: concepts[0],
    preExposures: pre_exposures,
    ideaMetrics: idea_metrics,
    processMetrics: process_metrics,
    fullConceptMetrics: full_concept_metrics,
    deviationMetrics: deviation_metrics,
    qualifiedDemographicMetrics: qualified_demographic_metrics,
    translations,
    result,
    sample,
    screenersTitleNameMatching: screeners_title_name_matching,
    metricGroupStep, setMetricGroupStep, initialMetricGroupStep, groupIndex, step,
    setLoading, paths
  };

  return (
    <GeneralContext.Provider value={ generalContextValue }>
      <WithTagsAndAnswersUpdating>
        <WithCheckQuotas>
          <PageLoader loading={ loading || fraudProcessing } modifier="-gray" />
          { (loading || fraudProcessing) && <div className="sr-only">Loading</div>}
          <section className="survey-layout -verizon-customer-case-v2024" aria-hidden={ loading || fraudProcessing }>
            {
              step === 1 &&
              <Intro
                nextStep={ goForward }
                title={ translations.intro_start_title }
                description={ translations.intro_start_text }
              />
            }
            {
              step === 2 &&
              <QuestionsGroup
                key={ groupIndex }
                group={ filteredGroup(groupIndex) }
                nextGroup={ goForward }
                result={ result }
              />
            }
            {
              step === 3 &&
              <Screening
                question={ screenings[screeningIndex] }
                nextStep={ goForward }
                result={ result }
              />
            }
            {
              step === 4 &&
              <QuestionsGroup
                key={ groupIndex }
                group={ [ find(demographics, (el) => el.name === 'SO19') ] }
                nextGroup={ goForward }
                result={ result }
              />
            }
            {
              step === 5 &&
              <Qualified
                title={ translations.express_qualify_title }
                description={ translations.express_qualify_description }
                descriptionBottom={ translations.express_qualify_footer }
                nextStep={ goForward }
              />
            }
            { step === 6 && <PreExposure /> }
            { step === 7 && <Idea /> }
            { step === 8 && <Process /> }
            { step === 9 && <FullConcept /> }
            { step === 10 && <DeviationProcess /> }
            {
              step === 11 &&
              <FivePointScale
                concept={ concepts[0] }
                questions={ custom_metrics }
                result={ result }
                nextStep={ goForward }
              />
            }
            { step === 12 && <QualifiedDemographics /> }
            <LastStepsComponents
              step={ step }
              lastStepsFrom={ lastStep }
              data={ data }
              nextStep={ goForward }
            />
          </section>
        </WithCheckQuotas>
      </WithTagsAndAnswersUpdating>
    </GeneralContext.Provider>
  );
};

export default Index;
