import { find, intersection, uniq } from 'lodash';
import { fiosReside, prepaid, postpaid } from '../components/withTagsAndAnswersUpdating';

const findQuestionInGroup = (group, name) => find(
  group || [],
  (question) => question.title === name
);
const hasAnswers = (answers, searchAnswers, atLeastOne) => {
  const answeredArray = uniq(intersection(answers, searchAnswers));
  if (atLeastOne) {
    return answeredArray.length >= 1;
  }
  return answeredArray.length === searchAnswers.length;
};

const screeningByWirelessGenPop = (
  currentGroup, nextConfidentialAgreementQuestion, result, screenersTitleNameMatching
) => {
  const answers = result.answers;
  if (nextConfidentialAgreementQuestion) {
    // Verizon Wireless Customer in HidVZW (Q12) and Postpaid in HidService (Q14)
    // OR
    // Verizon Wireless Prospect in HidVZW (Q12) and (Postpaid OR Prepaid in HidService (Q14))
    const wirelessServiceUseName = screenersTitleNameMatching['Wireless Service Use'];
    const selectedVerizon = hasAnswers(answers, [ `${wirelessServiceUseName}_18` ]);
    const vzwCustomerAndPostpaid = selectedVerizon && postpaid(result);
    const vzwProspectAndPostpaidOrPrepaid = !selectedVerizon && (prepaid(result) || postpaid(result));
    return !(vzwCustomerAndPostpaid || vzwProspectAndPostpaidOrPrepaid);
  }
  return false;
};

const screeningByWirelessPostpaidCustomers = (
  currentGroup, nextConfidentialAgreementQuestion, result, screenersTitleNameMatching
) => {
  const answers = result.answers;

  // Must select 18 Verizon in S12) WIRELESS SERVICE USE
  const wirelessServiceUseTitle = 'Wireless Service Use';
  const wirelessServiceUseQuestion = findQuestionInGroup(currentGroup, wirelessServiceUseTitle);
  if (wirelessServiceUseQuestion) {
    const wirelessServiceUseName = screenersTitleNameMatching[wirelessServiceUseTitle];
    if (!hasAnswers(answers, [ `${wirelessServiceUseName}_18` ])) {
      return true;
    }
  }

  if (nextConfidentialAgreementQuestion) {
    // Must qualify as Postpaid in HidService variable;
    return !postpaid(result);
  }
  return false;
};

const screeningByWirelessPostpaidProspects = (
  currentGroup, nextConfidentialAgreementQuestion, result, screenersTitleNameMatching
) => {
  const answers = result.answers;

  // Must NOT select 18 Verizon in S12) WIRELESS SERVICE USE
  const wirelessServiceUseTitle = 'Wireless Service Use';
  const wirelessServiceUseQuestion = findQuestionInGroup(currentGroup, wirelessServiceUseTitle);
  if (wirelessServiceUseQuestion) {
    const wirelessServiceUseName = screenersTitleNameMatching[wirelessServiceUseTitle];
    if (hasAnswers(answers, [ `${wirelessServiceUseName}_18` ])) {
      return true;
    }
  }

  if (nextConfidentialAgreementQuestion) {
    // Must qualify as Postpaid in HidService variable;
    return !postpaid(result);
  }
  return false;
};

const screeningByWirelessPreOrPostPaidProspects = (
  currentGroup, nextConfidentialAgreementQuestion, result, screenersTitleNameMatching
) => {
  const answers = result.answers;

  // Must NOT select 18 Verizon in S12) WIRELESS SERVICE USE
  const wirelessServiceUseTitle = 'Wireless Service Use';
  const wirelessServiceUseQuestion = findQuestionInGroup(currentGroup, wirelessServiceUseTitle);
  if (wirelessServiceUseQuestion) {
    const wirelessServiceUseName = screenersTitleNameMatching[wirelessServiceUseTitle];
    if (hasAnswers(answers, [ `${wirelessServiceUseName}_18` ])) {
      return true;
    }
  }

  if (nextConfidentialAgreementQuestion) {
    // Either Prepaid OR Postpaid in HidService variable;
    return !(prepaid(result) || postpaid(result));
  }
  return false;
};

const screeningByHomeInternetGenPop = (
  currentGroup, nextConfidentialAgreementQuestion, result, screenersTitleNameMatching
) => {
  return false;
};

const screeningByHomeInternetFiosCustomers = (
  currentGroup, nextConfidentialAgreementQuestion, result, screenersTitleNameMatching
) => {
  const answers = result.answers;

  // Must select 21 Verizon Fios in S16) HOME INTERNET PROVIDER
  const homeInternetProviderTitle = 'Home Internet Provider';
  const homeInternetProviderQuestion = findQuestionInGroup(currentGroup, homeInternetProviderTitle);
  if (homeInternetProviderQuestion) {
    const homeInternetProviderName = screenersTitleNameMatching[homeInternetProviderTitle];
    if (!hasAnswers(answers, [ `${homeInternetProviderName}_21` ])) {
      return true;
    }
  }

  if (nextConfidentialAgreementQuestion) {
    // Must reside in Fios Footprint (HidFiosFootprint)
    return !fiosReside(result);
  }

  return false;
};

const screeningByHomeInternetFiosProspects = (
  currentGroup, nextConfidentialAgreementQuestion, result, screenersTitleNameMatching
) => {
  const answers = result.answers;

  // Must NOT select 21 Verizon Fios in S16) HOME INTERNET PROVIDER
  const homeInternetProviderTitle = 'Home Internet Provider';
  const homeInternetProviderQuestion = findQuestionInGroup(currentGroup, homeInternetProviderTitle);
  if (homeInternetProviderQuestion) {
    const homeInternetProviderName = screenersTitleNameMatching[homeInternetProviderTitle];
    if (hasAnswers(answers, [ `${homeInternetProviderName}_21` ])) {
      return true;
    }
  }

  if (nextConfidentialAgreementQuestion) {
    // Must reside in Fios Footprint (HidFiosFootprint)
    return !fiosReside(result);
  }

  return false;
};

const screeningByHomeInternetProspectsNationwide = (
  currentGroup, nextConfidentialAgreementQuestion, result, screenersTitleNameMatching
) => {
  const answers = result.answers;

  // Must NOT select 21 Verizon Fios OR 22 Verizon 5G Home / 5G Home + OR 23 Verizon LTE Home
  // in S16) HOME INTERNET PROVIDER
  const homeInternetProviderTitle = 'Home Internet Provider';
  const homeInternetProviderQuestion = findQuestionInGroup(currentGroup, homeInternetProviderTitle);
  if (homeInternetProviderQuestion) {
    const homeInternetProviderName = screenersTitleNameMatching[homeInternetProviderTitle];
    if (
      hasAnswers(
        answers,
        [
          `${homeInternetProviderName}_21`,
          `${homeInternetProviderName}_22`,
          `${homeInternetProviderName}_23`
        ],
        true
      )
    ) {
      return true;
    }
  }

  return false;
};

const screeningByHomeInternetCustomers = (
  currentGroup, nextConfidentialAgreementQuestion, result, screenersTitleNameMatching
) => {
  const answers = result.answers;

  // Must select 21 Verizon Fios, 22 Verizon 5G Home / 5G Home + OR 23 Verizon LTE Home in
  // S16) HOME INTERNET PROVIDER
  const homeInternetProviderTitle = 'Home Internet Provider';
  const homeInternetProviderQuestion = findQuestionInGroup(currentGroup, homeInternetProviderTitle);
  if (homeInternetProviderQuestion) {
    const homeInternetProviderName = screenersTitleNameMatching[homeInternetProviderTitle];
    if (
      !hasAnswers(
        answers,
        [
          `${homeInternetProviderName}_21`,
          `${homeInternetProviderName}_22`,
          `${homeInternetProviderName}_23`
        ],
        true
      )
    ) {
      return true;
    }
  }

  return false;
};

const screeningByCorporateGenPop = (
  currentGroup, nextConfidentialAgreementQuestion, result, screenersTitleNameMatching
) => {
  return false;
};

const screeningByCorporateCustomers = (
  currentGroup, nextConfidentialAgreementQuestion, result, screenersTitleNameMatching
) => {
  const answers = result.answers;
  if (nextConfidentialAgreementQuestion) {
    // Must qualify for AT LEAST ONE OF:
    //  1. HidVZW = VZW Customers AND HidService = Postpaid
    //  2. HidVHI = VZ Home Internet Customers
    const wirelessServiceUseName = screenersTitleNameMatching['Wireless Service Use'];
    const vzwCustomer = hasAnswers(answers, [ `${wirelessServiceUseName}_18` ]);
    const vzwCustomerAndPostpaid = vzwCustomer && postpaid(result);

    const homeInternetProvider = screenersTitleNameMatching['Home Internet Provider'];
    const homeInternetCustomer = hasAnswers(
      answers,
      [ `${homeInternetProvider}_21`, `${homeInternetProvider}_22`, `${homeInternetProvider}_23` ],
      true
    );

    return !(vzwCustomerAndPostpaid || homeInternetCustomer);
  }

  return false;
};

const screeningByFwaCustomers = (
  currentGroup, nextConfidentialAgreementQuestion, result, screenersTitleNameMatching
) => {
  const answers = result.answers;

  // Must select 2 Home Internet service in S10) SERVICES CURRENTLY USE
  const servicesCurrentlyUseTitle = 'Services Currently Use';
  const servicesCurrentlyUseQuestion = findQuestionInGroup(currentGroup, servicesCurrentlyUseTitle);
  if (servicesCurrentlyUseQuestion) {
    const servicesCurrentlyUseName = screenersTitleNameMatching[servicesCurrentlyUseTitle];
    if (!hasAnswers(answers, [ `${servicesCurrentlyUseName}_2` ])) {
      return true;
    }
  }

  // Must select 22 Verizon 5G Home / 5G Home + OR 23 Verizon LTE Home in
  // S16) HOME INTERNET PROVIDER
  const homeInternetProviderTitle = 'Home Internet Provider';
  const homeInternetProviderQuestion = findQuestionInGroup(currentGroup, homeInternetProviderTitle);
  if (homeInternetProviderQuestion) {
    const homeInternetProviderName = screenersTitleNameMatching[homeInternetProviderTitle];
    if (
      !hasAnswers(
        answers,
        [
          `${homeInternetProviderName}_22`,
          `${homeInternetProviderName}_23`
        ],
        true
      )
    ) {
      return true;
    }
  }

  // Must select 1 - I am the primary decision maker OR 2 - I share the decision jointly with someone else + OR
  // 3 - I have influence on the decision, but someone else makes the final decision in S17) HOME INTERNET PROVIDER
  const decisionMakerTitle = 'Decision Maker Home Internet';
  const decisionMakerTitleQuestion = findQuestionInGroup(currentGroup, decisionMakerTitle);
  if (decisionMakerTitleQuestion) {
    const decisionMakerName = screenersTitleNameMatching[decisionMakerTitle];
    if (
      !hasAnswers(
        answers,
        [
          `${decisionMakerName}_1`,
          `${decisionMakerName}_2`,
          `${decisionMakerName}_3`
        ],
        true
      )
    ) {
      return true;
    }
  }

  return false;
};


const screeningBySample = (
  sample, currentGroup, nextConfidentialAgreementQuestion, result, screenersTitleNameMatching
) => {
  switch (sample) {
    case 'wireless_gen_pop': // Gen Pop – Wireless
      return screeningByWirelessGenPop(
        currentGroup, nextConfidentialAgreementQuestion, result, screenersTitleNameMatching
      );
    case 'wireless_customers': // VZW Postpaid Customers
      return screeningByWirelessPostpaidCustomers(
        currentGroup, nextConfidentialAgreementQuestion, result, screenersTitleNameMatching
      );
    case 'wireless_prospects_postpaid': // VZW Postpaid Prospects
      return screeningByWirelessPostpaidProspects(
        currentGroup, nextConfidentialAgreementQuestion, result, screenersTitleNameMatching
      );
    case 'wireless_prospects_prepaid': // VZW Prospects (Pre or Post-paid)
      return screeningByWirelessPreOrPostPaidProspects(
        currentGroup, nextConfidentialAgreementQuestion, result, screenersTitleNameMatching
      );
    case 'home_internet_gen_pop': // Gen Pop – Home Internet
      return screeningByHomeInternetGenPop(
        currentGroup, nextConfidentialAgreementQuestion, result, screenersTitleNameMatching
      );
    case 'home_internet_fios_customers': // Fios Home Internet Customers (defined as within Fios footprint)
      return screeningByHomeInternetFiosCustomers(
        currentGroup, nextConfidentialAgreementQuestion, result, screenersTitleNameMatching
      );
    case 'home_internet_fios_prospects': // Fios Home Internet Prospects (defined as within Fios footprint)
      return screeningByHomeInternetFiosProspects(
        currentGroup, nextConfidentialAgreementQuestion, result, screenersTitleNameMatching
      );
    case 'home_internet_home_prospects': // Home Internet Prospects (reside Nationwide)
      return screeningByHomeInternetProspectsNationwide(
        currentGroup, nextConfidentialAgreementQuestion, result, screenersTitleNameMatching
      );
    case 'home_internet_home_customers': // VZ Home Internet Customers (Fios or FWA Customers)
      return screeningByHomeInternetCustomers(
        currentGroup, nextConfidentialAgreementQuestion, result, screenersTitleNameMatching
      );
    case 'corporate_gen_pop': // Gen Pop – Corporate Concepts
      return screeningByCorporateGenPop(
        currentGroup, nextConfidentialAgreementQuestion, result, screenersTitleNameMatching
      );
    case 'corporate_customers': // VZ Customers (either VZW Postpaid or Home Internet Customers)
      return screeningByCorporateCustomers(
        currentGroup, nextConfidentialAgreementQuestion, result, screenersTitleNameMatching
      );
    case 'home_internet_fwa_customers': // FWA Customers
      return screeningByFwaCustomers(
        currentGroup, nextConfidentialAgreementQuestion, result, screenersTitleNameMatching
      );
    default:
      return false;
  }
};

export { screeningBySample };
