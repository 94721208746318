import React, { createContext, useContext, useState, useLayoutEffect } from 'react';
import { connect } from 'react-redux';
import { find } from 'lodash';
import Forward from '../../common/logic/naming/forward.js';
import saveUserData from '../../common/logic/naming/save_user_data.js';
import renderRawHtml from '../../../../common/render_raw_html.js';
import PageLoader from '../../common/components/page_loader.js';
import Intro from '../../common/components/intro.js';
import Screening from '../../common/components/screening.js';
import Qualified from '../../common/components/qualified.js';
import CustomQualified from '../../common/components/custom_qualified.js';
import ProductIntro from '../../common/components/product_intro.js';
import Preference from '../../common/components/metrics/preference.js';
import PreferenceReasons from '../../common/components/metrics/preference_reasons.js';
import ProductLink from '../../common/components/product_link.js';
import runPreload from "../logic/preload";
import { fraudContext, SurveyContext } from "../../common/contexts";
import { LastStepsComponents } from '../../common/logic/last_steps';
import CustomMetricsInLocation from "../../common/components/metrics/custom_metrics_in_location";
import { customMetricsByLocation } from "../../common/logic/eligible";
import WithOpenEnd from '../../common/components/metrics/customMetricsOpenEnd/withOpenEnd';
import Concept from './concept.js';

const resultContext = createContext(
  {
    answers: [],
    loops: [],
    thought_bubbles: [],
    preference_views: [],
    preference_selections: [],
    preference_answers: []
  }
);

const Index = ({
  data, updateStep, updateConceptStep, updateConceptIndex,
  updateQuestionIndex, cIndex, qIndex, step, conceptStep,
  changeLoadingState, loading
}) => {
  const {
    concepts, monadic, demographics, optional_demographics,
    screenings, translations, product_image_url, task,
    save_url, qualified_custom_text, with_images, show_task, show_task_template,
    concepts_for_preference, preference_all
  } = data;

  const preferenceTitle = preference_all ?
    translations.preference_all_title :
    translations.preference_title;
  const linkText = translations.link_to_product_description;
  const productUrl = product_image_url;
  const productText = task;
  let productTitle;
  if (show_task_template) {
    productTitle = (monadic ?
      translations.product_intro_monadic :
      translations.product_intro_sequential
    );
  }
  const qualifiedDescription = (monadic ?
    translations.express_qualify_description_monadic :
    translations.express_qualify_description_sequential
  );
  const withImages = with_images;

  const result = useContext(resultContext);
  const { projectId, antiFraud, fraudProcessing, gaTrackingId } = useContext(fraudContext);

  const saveRequest = (status) => {
    saveUserData(save_url, status, result, data);
  };

  const [ forwardEntity ] = useState(new Forward({
    updateStep,
    runSave: (status) => saveRequest(status),
    updateConceptStep,
    updateConceptIndex,
    updateQuestionIndex
  }));
  const currentConcept = () => concepts[cIndex];
  const currentQuestion = (questions) => questions[qIndex];
  const goForward = () => {
    forwardEntity.forward(step, data, result, projectId, antiFraud, gaTrackingId);
  };
  const goNextConcept = () => {
    forwardEntity.nextConceptStep(
      conceptStep, cIndex, currentConcept(), data, goForward
    );
  };
  const goNextQuestion = (questions) => {
    forwardEntity.nextQuestion(
      qIndex, questions, result, goForward
    );
  };

  const preferenceSelectedConcept = () => {
    const selection = result.preference_selections[0];
    if (!selection) {
      return null;
    }
    return find(concepts_for_preference, (c) => c.id === parseInt(selection.concept_id));
  };

  const reasonsTitle = () => {
    const concept = preferenceSelectedConcept();
    return translations.reasons_for_preference_title.replace('%currentName%', (concept ? concept.name : ''));
  };

  useLayoutEffect(() => {
    runPreload([ ...concepts, ...concepts_for_preference ], changeLoadingState, withImages);
  }, []);

  const productLink = (
    <ProductLink linkText={ linkText } productUrl={ productUrl } productText={ productText } />
  );

  const productDescriptionComponent = (<>{ show_task && productLink }</>);

  const surveyContextValue = { productLink, productDescriptionComponent };

  return (
    <SurveyContext.Provider value={ surveyContextValue }>
      <PageLoader loading={ loading || fraudProcessing } />
      <section className="survey-layout">
        {
          !step &&
          <Intro
            task={ translations.base_intro }
            nextStep={ goForward }
          />
        }
        {
          step === 2 &&
          <Screening
            question={ currentQuestion(demographics) }
            nextStep={ () => { goNextQuestion(demographics); } }
            result={ result }
          />
        }
        {
          step === 3 &&
          <Screening
            question={ currentQuestion(optional_demographics) }
            nextStep={ () => { goNextQuestion(optional_demographics); } }
            result={ result }
          />
        }
        {
          step === 4 &&
          <Screening
            question={ currentQuestion(screenings) }
            nextStep={ () => { goNextQuestion(screenings); } }
            result={ result }
          />
        }
        {
          step === 5 && !qualified_custom_text &&
          <Qualified
            topKlass="-naming"
            title={ translations.express_qualify_title }
            description={ qualifiedDescription }
            descriptionFooter={ translations.express_qualify_footer }
            nextStep={ goForward }
          />
        }
        {
          step === 5 && !!qualified_custom_text &&
          <CustomQualified
            content={ qualified_custom_text }
            nextStep={ goForward }
          />
        }
        {
          step === 6 &&
          <WithOpenEnd
            question={ currentQuestion(customMetricsByLocation(data.survey_metrics, "at_the_start")) }
            nextStep={ () => { goNextQuestion(customMetricsByLocation(data.survey_metrics, "at_the_start")); } }
            result={ result }
            mainResult={ result }
          />
        }
        {
          step === 7 &&
          <CustomMetricsInLocation
            location="at_the_start"
            concepts={ concepts }
            data={ data }
            goForward={ goForward }
            result={ result }
            forceImage={ withImages }
            productDescriptionComponent={ productDescriptionComponent }
          />
        }
        {
          step === 8 &&
          <ProductIntro
            title={ productTitle }
            url={ productUrl }
            text={ productText }
            nextStep={ goForward }
          />
        }
        {
          step === 9 &&
          <Concept
            concept={ currentConcept() }
            data={ data }
            nextStep={ () => { goNextConcept(); } }
            monadic={ monadic }
            index={ conceptStep }
            conceptIndex={ cIndex }
            result={ result }
            linkText={ linkText }
            productUrl={ productUrl }
            productText={ productText }
            forceImage={ withImages }
          />
        }
        {
          step === 10 &&
          <WithOpenEnd
            question={ currentQuestion(customMetricsByLocation(data.survey_metrics, "default")) }
            nextStep={ () => { goNextQuestion(customMetricsByLocation(data.survey_metrics, "default")); } }
            result={ result }
            mainResult={ result }
          />
        }
        {
          step === 11 &&
          <CustomMetricsInLocation
            location="at_the_end"
            concepts={ concepts }
            data={ data }
            goForward={ goForward }
            result={ result }
            forceImage={ withImages }
            productDescriptionComponent={ productDescriptionComponent }
          />
        }
        {
          step === 12 &&
          <Preference
            concepts={ concepts_for_preference }
            result={ result }
            nextStep={ goForward }
            forceImage={ withImages }
          >
            <div className="survey-header">
              <div className="survey-header_title">
                <div className="survey-start_text -low-margin-bottom" { ...renderRawHtml(preferenceTitle) } />
                { show_task && productLink }
              </div>
            </div>
          </Preference>
        }
        {
          step === 13 &&
          <PreferenceReasons
            concept={ preferenceSelectedConcept() }
            result={ result }
            note={ translations.reasons_for_preference_note }
            placeholder={ translations.reasons_for_preference_placeholder }
            nextStep={ goForward }
            forceImage={ withImages }
          >
            <div className="survey-header">
              <div className="survey-header_title">
                <div className="survey-start_text -low-margin-bottom" { ...renderRawHtml(reasonsTitle()) } />
                { show_task && productLink }
              </div>
            </div>
          </PreferenceReasons>
        }
        {
          step === 14 &&
          <WithOpenEnd
            question={ currentQuestion(customMetricsByLocation(data.survey_metrics, "at_the_end")) }
            nextStep={ () => { goNextQuestion(customMetricsByLocation(data.survey_metrics, "at_the_end")); } }
            result={ result }
            mainResult={ result }
          />
        }
        <LastStepsComponents
          step={ step }
          lastStepsFrom={ 15 }
          translations={ translations }
          data={ data }
          nextStep={ goForward }
        />
      </section>
    </SurveyContext.Provider>
  );
};

const mapStateToProps = (state) => ({ ...state });

const mapDispatchToProps = (dispatch) => {
  return {
    updateStep: (step) => { return dispatch({ type: "UPDATE_STEP", value: step }); },
    changeLoadingState: (value) => { return dispatch({ type: "UPDATE_LOADING", value }); },
    updateConceptStep: (step) => { return dispatch({ type: "UPDATE_CONCEPT_STEP", value: step }); },
    updateConceptIndex: (cIndex) => { return dispatch({ type: "UPDATE_CONCEPT_INDEX", value: cIndex }); },
    updateQuestionIndex: (qIndex) => { return dispatch({ type: "UPDATE_QUESTION_INDEX", value: qIndex }); }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Index);
