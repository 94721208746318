import React, { useContext, useEffect } from 'react';
import { debounce } from "lodash";
import { IndexData } from './contexts';

const Filter = () => {
  const { data, startDt, setStartDt, endDt, setEndDt, reloadData } = useContext(IndexData);

  const reloadDataRef = React.useRef(reloadData);
  reloadDataRef.current = reloadData;

  const handleChangeStartDebounce = React.useRef(
    debounce((ev) => {reloadDataRef.current({ newStartDt: ev.target.value });}, 500)
  ).current;

  const handleChangeStart = (event) => {
    setStartDt(event.target.value);
    handleChangeStartDebounce(event);
  };

  const handleChangeEndDebounce = React.useRef(
    debounce((ev) => {reloadDataRef.current({ newEndDt: ev.target.value });}, 500)
  ).current;

  const handleChangeEnd = (event) => {
    setEndDt(event.target.value);
    handleChangeEndDebounce(event);
  };

  const handleReset = () => {
    reloadData({
      newStartDt: data.time_frame.default_start,
      newEndDt: data.time_frame.default_end
    });
  };

  useEffect(() => {
    return () => {
      handleChangeStart.cancel();
    };
  }, [ handleChangeStart ]);

  useEffect(() => {
    return () => {
      handleChangeEndDebounce.cancel();
    };
  }, [ handleChangeEndDebounce ]);

  const showReset = data.time_frame.start !== data.time_frame.default_start || data.time_frame.end !== data.time_frame.default_end;

  return (
    <div className="form_section">
      <div className="form_grid">
        <div className="form_grid-item">
          <h3 className="form_section-title">
            Time Frame
          </h3>
          <div className="form-group -p-relative">
            <input
              className="form-field -md"
              type="date"
              value={ startDt }
              onChange={ handleChangeStart }
            />
            <input
              className="form-field -md -with-left-margin"
              type="date"
              value={ endDt }
              onChange={ handleChangeEnd }
            />
            {showReset &&
              <button
                type="button"
                className="link reset-link"
                onClick={ handleReset }
              >
                Reset to default
              </button>
            }
            {data.time_frame.message && <div className="comparisons_plate-note -margin-left -tiny-margin-top">{data.time_frame.message}</div>}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Filter;
