import React, { useContext, useState } from 'react';
import { map, find } from "lodash";
import SliderRadioButtons from '../../../express/researcher/verizon_customer_case_v2024/components/sliderRadioButtons';
import SingleSelect from '../executiveView/singleSelect';
import { IndexData } from './contexts';
import Plot from './plot';

const Trends = () => {
  const { data, groupBy, reloadData, loading } = useContext(IndexData);

  const [ metric, setMetric ] = useState('logins-total');

  const handleChangeGroupBy = (v) => {
    reloadData({ newGroupBy: v });
  };

  const currentMetric = find(data.by_intervals, (v) => (v.metric === metric));
  const options = map(data.by_intervals, (v) => ({ value: v.metric, label: v.title }));
  const handleOnChange = (v) => {
    setMetric(v.value);
  };

  return (
    <div className="comparisons_plate -bordered">
      <h3 className="form_section-title">Trends</h3>
      <hr />

      <div className="form_section">
        <div className="form_grid">
          <div className="form_grid-item -grow">
            <h3 className="form_section-title">Metric</h3>
            <div className="form-group">
              <SingleSelect
                selected={ metric }
                options={ options }
                onChange={ handleOnChange }
              />
            </div>
          </div>

          <div className="form_grid-item -width-1-2">
            <h3 className="form_section-title">&nbsp;</h3>
            <div className="form-group">
              <SliderRadioButtons
                initValue={ groupBy }
                options={
                  [
                    { value: 'day', title: 'Days' },
                    { value: 'week', title: 'Weeks' },
                    { value: 'month', title: 'Months' }
                  ]
                }
                onChange={ handleChangeGroupBy }
                modificator="-full-width -white-bg -bordered bsg-selector"
              />
              {currentMetric.message && <div className="comparisons_plate-note -center -tiny-margin-top">{currentMetric.message}</div>}
            </div>
          </div>
        </div>
        <div className="form_grid">
          <Plot
            groupBy={ groupBy }
            intervals={ data.intervals }
            metric={ currentMetric }
            loading={ loading }
          />
        </div>
      </div>
    </div>
  );
};

export default Trends;
