import React, { useEffect, useState } from 'react';
import Http from '../../../common/http';
import { vccPageEvent } from '../../../common/pageEventHelper';
import Header from './header';
import Filter from './filter';
import { IndexData } from "./contexts";
import Satisfaction from "./satisfaction";
import Visits from "./visits";
import Logins from "./logins";
import PageViews from "./pageViews";
import Downloads from "./downloads";
import Trends from "./trends";

const App = ({ initData, init }) => {
  const [ data, setData ] = useState(initData);
  const [ loading, setLoading ] = useState(false);
  const [ startDt, setStartDt ] = useState(data.time_frame.start);
  const [ endDt, setEndDt ] = useState(data.time_frame.end);
  const [ groupBy, setGroupBy ] = useState(data.time_frame.group_by);

  const reloadData = ({ newStartDt, newEndDt, newGroupBy }) => {
    const params = {};

    if (newStartDt) {
      setStartDt(newStartDt);
      params['start_dt'] = newStartDt;
    } else {
      params['start_dt'] = startDt;
    }

    if (newEndDt) {
      setEndDt(newEndDt);
      params['end_dt'] = newEndDt;
    } else {
      params['end_dt'] = endDt;
    }

    if (newGroupBy) {
      params['group_by'] = newGroupBy;
    } else {
      params['group_by'] = groupBy;
    }

    setLoading(true);

    Http.get(
      `/verizon_customer_case_v2024/api/program_health`,
      params
    ).then(
      (http) => {
        if (http.response.status === 200) {
          if (newGroupBy) {
            setGroupBy(newGroupBy);
          }
          setData(http.data);
          setLoading(false);
        }
      },
      (reject) => {
        console.log('something went wrong', reject);
      }
    );
  };

  const contextValue = {
    data,
    init,
    startDt,
    setStartDt,
    endDt,
    setEndDt,
    groupBy,
    reloadData,
    loading
  };

  useEffect(() => {
    if (init.verizon_roles.length) {
      vccPageEvent('page_view_program_health', 'program_health');
    }
  }, [ ]);

  return (
    <IndexData.Provider value={ contextValue }>
      <div className="content verizon-modifier -research -loading-wrapper program-health">
        <Header verizonRoles={ init.verizon_roles } />
        <Filter />
        <Satisfaction />
        <Visits />
        <Logins />
        <PageViews />
        <Downloads />
        <Trends />
      </div>
    </IndexData.Provider>
  );
};

export default App;
