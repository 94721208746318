import React, { useContext } from 'react';
import HeaderLinks from '../../common/components/header-links';
import { IndexData } from './contexts';

const Header = ({ verizonRoles }) => {
  const { data } = useContext(IndexData);

  const reports = [
    {
      title: 'Program Health',
      name: 'vcc2024_program_health',
      kinds: [ 'google' ],
      kindTitles: [ 'Google Sheets' ]
    }
  ];

  if (verizonRoles.length) {
    reports[0]['pageEvent'] = { kind: 'export_program_health', page: 'program_health' };
  }

  return (
    <div className="content_top form_section">
      <header className="header">
        <div className="header_grid">
          <div className="header_grid-item -grow">
            <h1 className="header_title">
              Program Health
            </h1>
          </div>
          <div className="projects_controls-grid-item">
            <HeaderLinks
              reports={ reports }
              dataHash={ data }
              requestUrl="/verizon_customer_case_v2024/api/program_health/report"
              requestKind="post"
            />
          </div>
        </div>
      </header>
    </div>
  );
};

export default Header;
