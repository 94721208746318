import React, { Fragment } from 'react';
import { map, range, max, startsWith, endsWith, findIndex, filter, each, find } from 'lodash';
import cn from "classnames";

const leftMargin = 20;
const topOffsetMargin = 0.4;
const labelTopOffset = 12;
const boxHeight = 23;

const Plot = ({ groupBy, intervals, metric, loading }) => {
  const allKeys = Object.keys(intervals);
  if (!allKeys.length) {
    return null;
  }

  const intervalKeys = filter(allKeys, (el) => (!metric.first_key || !!metric.first_key && el >= metric.first_key));

  const count = intervalKeys.length;

  const maxVal = max(Object.values(metric.records)) || 0.0;

  const x1 = nx(0, count), x2 = nx(count - 1, count);

  const valuesIndexed = map(range(0, count - 1), (n) => ([ metric.records[intervalKeys[n]], n ]));
  const valuesIndexedExisted = filter(valuesIndexed, (el) => el[0] !== undefined);
  const valuesExisted = map(valuesIndexedExisted, (el) => el[0]);
  valuesExisted.push(0);

  const peaksIndexes = minimalFindPeaks(valuesExisted, 0, maxVal / 15.0);

  const peaks = map(peaksIndexes, (i) => valuesIndexedExisted[i][1]);

  const valuePoints = map(range(0, count), (n) => {
    const value = metric.records[intervalKeys[n]] || 0;
    const hasValue = (metric.records[intervalKeys[n]] !== undefined && (value > 0));
    const x = nx(n, count);
    const dy = maxVal === 0 ? 0 : 400.0 * value / maxVal;
    const showMark = getShowLine(groupBy, n, count, intervals[intervalKeys[n]]);
    const isPeak = findIndex(peaks, (el) => el === n) > -1;
    const showText = true; //isPeak || value === maxVal && maxVal > 0 || n === 0 || n === count - 1;
    const width = getLabelWidth(groupBy);

    let minXDist = value < 10 ? 8 : 20;
    if (metric.is_percent) {
      minXDist = minXDist + 12;
    }

    const box = { x: x - minXDist / 2.0, y: 450 - dy, w: minXDist, h: 15 };
    return { x, y: 450 - dy, showMark, showText, width, value, hasValue, isPeak, key: intervalKeys[n], box };
  });

  for (let i = valuePoints.length - 1; i > 0; i--) {
    if (valuePoints[i].hasValue) {
      break;
    }
    valuePoints[i].hasValue = true;
  }

  const valuePointsWithValues = filter(valuePoints, (el) => el.hasValue);

  each(valuePointsWithValues, (el) => {
    el.showText = true;
  });

  each(valuePointsWithValues, (el, index) => {
    let previousItemShowText = null;
    let nearestSameValue = null;
    const nearestItems = [];
    for (let k = 0; k < index; k++) {
      const potentialPreviousItem = valuePointsWithValues[k];
      if (potentialPreviousItem.showText) {
        previousItemShowText = potentialPreviousItem;
      }
      if (
        potentialPreviousItem.showText &&
        potentialPreviousItem.value === el.value &&
        (el.x - potentialPreviousItem.x <= leftMargin)) {
        nearestSameValue = potentialPreviousItem;
      }
      if (
        potentialPreviousItem.showText &&
        (el.x - potentialPreviousItem.x <= leftMargin) &&
        ((Math.abs(el.box.y - potentialPreviousItem.box.y) / boxHeight) < topOffsetMargin)
      ) {
        nearestItems.push(potentialPreviousItem);
      }
    }
    if (
      (
        !!previousItemShowText &&
        previousItemShowText.value === el.value && (el.x - previousItemShowText.x <= leftMargin)
      ) || !!nearestSameValue
    ) {
      el.showText = false;
    }

    if (el.showText && !!nearestItems.length) {
      let highestValueEl = nearestItems[0];
      for (let k = 1; k < nearestItems.length; k++) {
        const currentNearestItem = nearestItems[k];
        if (currentNearestItem.value > highestValueEl.value) {
          highestValueEl = currentNearestItem;
        }
      }
      const shift = (0.5 * boxHeight);
      if (highestValueEl.value < el.value) {
        el.box.y = highestValueEl.box.y - shift;
      } else {
        el.box.y = highestValueEl.box.y + shift;
      }
    }
  });

  return (
    <svg className={ cn("scores", { '-loading': loading }) } viewBox={ `0 0 1000 500` }>
      <text
        className="gray"
        fontFamily="Arial"
        fontSize="16px"
        transform="translate(50,250) rotate(-90)"
        textAnchor="middle"
      >
        {metric.title}
      </text>

      <line
        className="gray"
        x1={ x1 } y1="450"
        x2={ x2 } y2="450"
        strokeWidth="3"
      />

      {map(valuePoints, (p, n) => {
        return (
          <Fragment key={ n }>
            {p.showMark &&
              <>
                <line className="gray" x1={ p.x } y1="50" x2={ p.x } y2="450" opacity="0.4" strokeWidth="1" />
                <circle className="gray" cx={ p.x } cy={ 450 } r={ 5 } />
                <rect className="period-bg" x={ p.x - p.width / 2 } y="465" width={ p.width } height="22" />
                <text fontFamily="Arial" fontSize="12px" fontWeight="bold" x={ p.x } y="480" textAnchor="middle">
                  {intervals[intervalKeys[n]]}
                </text>
              </>}
          </Fragment>
        );
      })}

      <polyline
        className="red"
        fill="none"
        points={ map(valuePointsWithValues, (el) => (`${el.x.round(2)},${el.y.round(2)}`)).join(' ') }
        strokeWidth="5"
      />

      {map(valuePoints, (p, n) => {
        return (
          <Fragment key={ n }>
            {
              p.value > 0 &&
              <circle className="red-ring" cx={ p.x } cy={ p.y } r={ 6 } strokeWidth="5" />
            }
          </Fragment>
        );
      })}

      {map(valuePoints, (p, n) => {
        return (
          <Fragment key={ n }>
            { p.showText && (p.value > 0) &&
              <Fragment>
                <text
                  className="bold"
                  fontFamily="Arial"
                  fontSize="18px"
                  textAnchor="middle"
                  x={ p.x }
                  y={ p.box.y - labelTopOffset }
                >
                  {formatV(p.value, metric.is_percent)}
                </text>
              </Fragment>
            }
          </Fragment>
        );
      })}
    </svg>
  );
};

const formatV = (v, isPercent) => {
  if (isPercent) {
    return `${v}%`;
  }

  return v;
};

const nx = (index, count) => {
  const delta = 400.0 / count;

  return 500 - ((count - 1) * delta) + 2 * delta * index;
};

const getShowLine = (groupBy, n, count, title) => {
  if (groupBy === 'day' && count > 1000) {
    return startsWith(title, "01/01");
  } else if (groupBy === 'day' && count > 70) {
    return endsWith(title, "/01");
  } else if (groupBy === 'day' && count > 24) {
    return n % 3 === 0;
  } else if (groupBy === 'day' && count > 12) {
    return n % 2 === 0;
  } else if (groupBy === 'week' && count > 9) {
    const magic = Math.floor(100 / (540.0 / count));
    return n % magic === 0;
  } else if (groupBy === 'month' && count > 36) {
    return startsWith(title, "Jan");
  }

  return true;
};

const getLabelWidth = (groupBy) => {
  if (groupBy === 'day') {
    return 36;
  }
  if (groupBy === 'week') {
    return 74;
  }

  return 45;
};

const findLocalMaxima = (xs) => {
  const maxima = [];

  for (let i = 1; i < xs.length - 1; ++i) {
    const pointPeak = xs[i] > xs[i - 1] && xs[i] > xs[i + 1];
    const leftCliff = xs[i] === xs[i - 1] && xs[i] > xs[i + 1];
    const rightCliff = xs[i] > xs[i - 1] && xs[i] === xs[i + 1];

    if (leftCliff || pointPeak || rightCliff) {
      maxima.push(i);
    }
  }

  return maxima;
};

const filterByHeight = (indices, xs, height) => {
  return indices.filter((i) => xs[i] > height);
};

const decor = (v, i) => [ v, i ];
const undecor = (pair) => pair[1];
const argsort = (arr) => arr.map(decor).sort().map(undecor);

const filterByDistance = (indices, xs, dist) => {
  const to_remove = Array(indices.length).fill(false);
  const heights = indices.map((i) => xs[i]);
  const sorted_index_positions = argsort(heights).reverse();

  for (const current of sorted_index_positions) {
    if (to_remove[current]) {
      continue;  // peak will already be removed, move on.
    }

    let neighbor = current - 1;  // check on left side of peak
    while (neighbor >= 0 && (indices[current] - indices[neighbor]) < dist) {
      to_remove[neighbor] = true;
      --neighbor;
    }

    neighbor = current + 1;  // check on right side of peak
    while (neighbor < indices.length && (indices[neighbor] - indices[current]) < dist) {
      to_remove[neighbor] = true;
      ++neighbor;
    }
  }
  return indices.filter((v, i) => !to_remove[i]);
};


const filterMaxima = (indices, xs, distance, height) => {
  let new_indices = indices;
  if (height !== undefined) {
    new_indices = filterByHeight(indices, xs, height);
  }
  if (distance !== undefined) {
    new_indices = filterByDistance(new_indices, xs, distance);
  }
  return new_indices;
};

const minimalFindPeaks = (xs, distance, height) => {
  const indices = findLocalMaxima(xs);
  return filterMaxima(indices, xs, distance, height);
};

const boxOverlapping = (el, index, valuePointsWithValues, force = false) => {
  let overlap = false;

  if (el.value > 0 && (el.showText || force)) {
    let i = 1;
    let elOth = valuePointsWithValues[index - i];
    while (index - i >= 0 && Math.abs(el.x - elOth.x) < 32) {
      if (elOth.value > 0 && elOth.showText && boxesOverlapping(el.box, elOth.box)) {
        overlap = true;
        break;
      }
      i++;
      elOth = valuePointsWithValues[index - i];
    }

    i = 1;
    elOth = valuePointsWithValues[index + i];
    while (index + i < valuePointsWithValues.length && Math.abs(elOth.x - el.x) < 32) {
      if (elOth.value > 0 && elOth.showText && boxesOverlapping(el.box, elOth.box)) {
        overlap = true;
        break;
      }
      i++;
      elOth = valuePointsWithValues[index + i];
    }
  }

  return overlap;
};

const boxesOverlapping = (b1, b2) => {
  const res =  (b1.x + b1.w) > b2.x && b1.x < (b2.x + b2.w) &&
    (b1.y + b1.h) > b2.y && b1.y < (b2.y + b2.h);
  return res;
};

export default Plot;
