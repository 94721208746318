import React, { useContext } from 'react';
import { IndexData } from './contexts';
import Block from './block';

const Logins = () => {
  const { data: { logins } } = useContext(IndexData);

  return (
    <>
      <div className="comparisons_plates">
        <div className="comparisons_section">
          <h2 className="comparisons_section-title -no-margin">
            Logins
          </h2>
        </div>
        <div className="comparisons_plate-note -black">
          (among VZ account users)
        </div>
      </div>
      <div className="comparisons_plates">
        <div className="comparisons_plates-grid">
          <Block
            className="-logins"
            header="Total Logins"
            value={ logins.total }
            hint="Total number of logins across all users."
          />
          <Block
            className="-logins"
            header="Unique Logins"
            value={ logins.uniq }
            hint="Number of distinct users who have logged in."
          />
          <Block
            className="-logins"
            header="Logins Per Day"
            value={ logins.per_day }
            hint="Average number of logins per day."
          />
          <Block
            className="-logins"
            header="Time Between Logins"
            value={ logins.time_between }
            hint="Avg number of days between first and last login."
          />
        </div>
      </div>
    </>
  );
};

export default Logins;
