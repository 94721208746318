import React, { useContext } from 'react';
import { IndexData } from './contexts';
import Block from './block';

const Visits = () => {
  const { data: { visits } } = useContext(IndexData);

  return (
    <>
      <div className="comparisons_plates">
        <div className="comparisons_section">
          <h2 className="comparisons_section-title -no-margin">
            Dashboards Visits
          </h2>
        </div>
        <div className="comparisons_plate-note -black">
          (among VZ account users)
        </div>
      </div>
      <div className="comparisons_plates">
        <div className="comparisons_plates-grid">
          <Block
            className="-visits"
            header="Total Visits"
            value={ visits.total }
            hint="Total number of visits across all users."
          />
          <Block
            className="-visits"
            header="Unique Visits"
            value={ visits.uniq }
            hint="Number of distinct users who have visited dashboard."
          />
          <Block
            className="-visits"
            header="Visits Per Day"
            value={ visits.per_day }
            hint="Average number of visits per day."
          />
          <Block
            className="-visits"
            header="Time Between Visits"
            value={ visits.time_between }
            hint="Avg number of days between first and last visit."
          />
        </div>
      </div>
    </>
  );
};

export default Visits;
