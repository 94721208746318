import React, { useContext } from 'react';
import HeaderLinks from '../../common/components/header-links';
import { IndexData } from "./contexts";

const Header = ({ verizonRoles }) => {
  const { data } = useContext(IndexData);
  const reports = [
    {
      title: 'Executive Snapshot View',
      name: 'vcc_executive',
      kinds: [ 'google', 'google_pdf' ],
      kindTitles: [ 'Google Slides', 'PDF' ]
    }
  ];

  if (verizonRoles.length) {
    reports[0]['pageEvent'] = { kind: 'export_executive_view', page: 'executive_view' };
  }

  return (
    <div className="content_top form_section">
      <header className="header">
        <div className="header_grid">
          <div className="header_grid-item -grow">
            <h1 className="header_title">
              Executive Snapshot View
            </h1>
          </div>
          <div className="header_grid-item">
            <HeaderLinks
              reports={ reports }
              dataHash={ JSON.stringify(data) }
              requestUrl="/verizon_customer_case_v2024/api/executive/report"
              requestKind="post"
            />
          </div>
        </div>
      </header>
    </div>
  );
};

export default Header;
