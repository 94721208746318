import React, { Fragment, useRef, useState, useEffect } from 'react';
import { find, isEmpty, map, includes } from 'lodash';
import classnames from 'classnames';
import Header from "../../common/header";
import WithSubmit from "../../common/WithSubmit";
import FieldHeaderWithText from "../../common/FieldHeaderWithText";
import Select from "../common/select";
import DatePicker from "../common/datePicker";
import PageLoader from '../../common/PageLoader';
import Radio from '../../common/radio';
import Wysiwyg from '../../../../common/components/wysiwyg';
import Toggle from '../../common/toggle';
import HiddenFields from "./hiddenFields";
import EmailsList from './emailsList';

const App = ({ data, options, errors }) => {
  const [ model, setModel ] = useState({
    platformId: data.platform_id || '',
    title: data.title || '',
    segment: data.verizon_express_setting.segment || '',
    testKind: data.verizon_express_setting.test_kind || '',
    branded: data.verizon_express_setting.branded || '',
    brand: data.verizon_express_setting.brand || '',
    note: data.note || '',
    submissionDate: data.verizon_express_setting.submission_date || '',
    softwareProduct: data.verizon_express_setting.software_product || '',
    audienceCategory: data.verizon_express_setting.audience_category || '',
    subgroups: (
      data.verizon_express_setting.subgroups ||
      [ find(options.subgroups_list, (item) => item.default_check).value ]
    ),
    subgroupsOther: data.verizon_express_setting.subgroups_other || '',
    projectLeadEmail: data.verizon_express_setting.project_lead_email || '',
    executiveRequestFlag: data.verizon_express_setting.executive_request_flag || '',
    darkProject: data.dark_project || '',
    cmiLeadEmailFirst: data.verizon_express_setting.cmi_lead_email_first || '',
    cmiLeadEmailSecond: data.verizon_express_setting.cmi_lead_email_second || '',
    secondaryCmiLeadEmail: data.verizon_express_setting.secondary_cmi_lead_email || '',
    emails: data.verizon_express_setting.emails || [],
    idea: data.verizon_express_setting.idea || '',
    journeyStage: data.verizon_express_setting.journey_stage || '',
    errors
  });

  const maxEmails = options.max_emails;

  const initHasChanges = useRef(options.generate || !isEmpty(errors));
  const hasChanges = useRef(initHasChanges.current);

  const subgroupSelected = (value) => (model.subgroups[0] === value);

  const toggleSubgroup = (item) => {
    model.subgroups = [];
    model.subgroups.push(item.value);
    setModel({ ...model, subgroups: model.subgroups });
  };

  const handleUpdateSubgroupOther = (value) => {
    setModel({ ...model, subgroupsOther: value });
  };

  const segmentOptions = (
    model.audienceCategory === "wireless" ?
      options.segment_wireless_options :
      options.segment_home_internet_options
  );

  const getBrandOptions = (branded, audienceCategory, segment) => {
    if (branded === 'branded' && audienceCategory === 'home_internet' && segment === 'fwa_customers') {
      return options.brand_fwa_options;
    }

    if (branded === 'branded' && audienceCategory === 'wireless') {
      return options.brand_wireless_options;
    }

    if (branded === 'branded' && audienceCategory === 'home_internet') {
      return options.brand_home_internet_options;
    }

    if (branded === 'branded_tmobile') {
      return options.brand_tmobile_options;
    }

    if (branded === 'branded_att') {
      return options.brand_att_options;
    }

    if (branded === 'unbranded' && audienceCategory === 'wireless') {
      return options.unbranded_wireless_options;
    }

    if (branded === 'unbranded' && audienceCategory === 'home_internet') {
      return options.unbranded_home_internet_options;
    }

    return [];
  };

  const brandOptions = () => {
    return getBrandOptions(model.branded, model.audienceCategory, model.segment);
  };

  const handleJourneyStageChange = (value) => {
    setModel({ ...model, journeyStage: value });
  };

  const handleAudienceCategoryChange = (value) => {
    setModel({ ...model, audienceCategory: value });
  };

  const handleBrandedChange = (v) => {
    const opts = getBrandOptions(v, model.audienceCategory, model.segment);

    const brand = find(opts, (el) => (el.value === model.brand)) || opts[0].value;

    setModel({ ...model, branded: v, brand });
  };

  useEffect(() => {
    const selectedSegment = (
      includes(map(segmentOptions, (item) => item.value), model.segment) ?
        model.segment :
        segmentOptions[0].value
    );
    const opts = brandOptions();
    const selectedBrand = (includes(map(opts, (item) => item.value), model.brand) ? model.brand : opts[0].value);
    setModel({ ...model, ...{ segment: selectedSegment, brand: selectedBrand } });
  }, [ model.audienceCategory ]);

  return (
    <WithSubmit formId="setup_form" errors={ errors } hasChangesFunc={ () => (hasChanges) } options={ options }>
      <HiddenFields data={ data } options={ options } model={ model } />
      <div className="content -relative">
        <Header title="Project Setup" />

        <div className="content_body">
          <div className="form">
            {!!options.jid && <PageLoader options={ options } />}
            <FieldHeaderWithText
              id="project_title"
              title="Project Name"
              placeholder="Please name your project"
              errors={ errors['title'] }
              initValue={ model.title }
              onChange={ (v) => {setModel({ ...model, title: v });} }
            />

            <FieldHeaderWithText
              id="project_platform_id"
              title="Buzzback project number"
              placeholder="Enter project number"
              errors={ errors['platform_id'] }
              initValue={ model.platformId }
              onChange={ (v) => {setModel({ ...model, platformId: v });} }
            />

            <div className="form_section">
              <Select
                id="select_journey_stage"
                title="Journey Stage"
                initValue={ model.journeyStage }
                options={ options.journey_stage_options }
                onChange={ handleJourneyStageChange }
                errors={ errors['verizon_express_setting.journey_stage'] }
              />
            </div>

            <div className="form_section">
              <FieldHeaderWithText
                id="project_project_lead_email"
                title="Project Lead"
                placeholder="Enter project lead email"
                errors={ errors['verizon_express_setting.project_lead_email'] }
                initValue={ model.projectLeadEmail }
                onChange={ (v) => {setModel({ ...model, projectLeadEmail: v });} }
              />
            </div>

            <div className="form_section">
              <div className="form_grid">
                <div className="form_grid-item -width-1-2">
                  <Toggle
                    className={ '-brand-user-only' }
                    checked={ model.darkProject }
                    hint={ <>Dark Project toggle is used to limit access to the case for the applicable user groups only</> }
                    onChange={ (v) => { setModel({ ...model, darkProject: v }); } }
                  >
                    Dark Project
                  </Toggle>
                </div>
                <div className="form_grid-item -width-1-2">
                  <Toggle
                    className={ '-brand-user-only' }
                    checked={ model.executiveRequestFlag }
                    hint={ <>If checked, the results will be sent to &quot;deborah.campbell&amp;verizon.com&quot;</> }
                    onChange={ (v) => { setModel({ ...model, executiveRequestFlag: v }); } }
                  >
                    Executive Request Flag
                  </Toggle>
                </div>
              </div>
            </div>

            <div className="form_section">
              <div className="form_grid">
                <div className="form_grid-item -width-1-2">
                  <FieldHeaderWithText
                    id="project_cmi_lead_email_first"
                    title="CMI Lead Email"
                    placeholder="Enter CMI lead email"
                    errors={ errors['verizon_express_setting.cmi_lead_email_first'] }
                    initValue={ model.cmiLeadEmailFirst }
                    onChange={ (v) => {setModel({ ...model, cmiLeadEmailFirst: v });} }
                  />
                </div>
                <div className="form_grid-item -width-1-2">
                  <FieldHeaderWithText
                    id="project_cmi_lead_email_second"
                    title="CMI Lead Email #2"
                    placeholder="Enter CMI lead email #2"
                    errors={ errors['verizon_express_setting.cmi_lead_email_second'] }
                    initValue={ model.cmiLeadEmailSecond }
                    onChange={ (v) => {setModel({ ...model, cmiLeadEmailSecond: v });} }
                  />
                </div>
              </div>
            </div>

            <div className="form_section">
              <FieldHeaderWithText
                id="field_secondary_cmi_lead_email"
                title="Secondary CMI Lead Email"
                placeholder="Enter secondary CMI Lead Email"
                errors={ errors['verizon_express_setting.secondary_cmi_lead_email'] }
                initValue={ model.secondaryCmiLeadEmail }
                onChange={ (v) => {setModel({ ...model, secondaryCmiLeadEmail: v });} }
              />
            </div>

            <div className="form_section">
              <div className="form_grid">
                <div className="form_grid-item -width-1-2">
                  <DatePicker
                    id="sub-dt"
                    title="Submission Date"
                    initValue={ model.submissionDate }
                    onChange={ (v) => {setModel({ ...model, submissionDate: v });} }
                    errors={ errors['verizon_express_setting.submission_date'] }
                  />
                </div>
                <div className="form_grid-item -width-1-2">
                  <Select
                    id="software_product"
                    title="Software Product"
                    initValue={ model.softwareProduct }
                    options={ options.software_product_options }
                    onChange={ (v) => {setModel({ ...model, softwareProduct: v });} }
                    errors={ errors['verizon_express_setting.software_product'] }
                    addEmpty
                  />
                </div>
              </div>
            </div>

            <div className="form_section">
              <div className="form_grid">
                <div className="form_grid-item -width-1-2">
                  <Select
                    id="audience_category"
                    title="Test Topic"
                    initValue={ model.audienceCategory }
                    options={ options.audience_category_options }
                    onChange={ handleAudienceCategoryChange }
                    errors={ errors['verizon_express_setting.audience_category'] }
                  />
                </div>
                <div className="form_grid-item -width-1-2">
                  <Select
                    key={ `${model.audienceCategory}-${model.branded}` }
                    id="segment"
                    title="Audience"
                    initValue={ model.segment }
                    options={ segmentOptions }
                    onChange={ (v) => {setModel({ ...model, segment: v });} }
                    errors={ errors['verizon_express_setting.segment'] }
                  />
                </div>
              </div>
            </div>

            <div className="form_section">
              <div className="form_grid">
                <div className="form_grid-item -width-1-2">
                  <Select
                    id="project_branded"
                    title="Branded"
                    initValue={ model.branded }
                    options={ options.branded_options }
                    onChange={ handleBrandedChange }
                    errors={ errors['verizon_express_setting.branded'] }
                  />
                </div>

                <div className="form_grid-item -width-1-2">
                  <Select
                    id="project_brand"
                    title="Brand"
                    initValue={ model.brand }
                    options={ brandOptions() }
                    onChange={ (v) => {setModel({ ...model, brand: v });} }
                    errors={ errors['verizon_express_setting.brand'] }
                    disabled={ model.branded !== 'branded' }
                  />
                </div>
              </div>
            </div>

            <div className="form_section -js-idea-description">
              <div className="form_section-title -grid">
                <div className="form_section-title-item">
                  <h3>Idea Description</h3>
                </div>
              </div>
              <div className={ classnames("form_section-line", { 'has-error': !!errors['verizon_express_setting.idea'] }) }>
                <Wysiwyg
                  customPicker
                  toolbarClassName="wysiwyg-menu"
                  editorClassName="wysiwyg-textarea"
                  placeholder="Add Text"
                  enableIndent
                  value={ model.idea }
                  onChange={ (newHtml) => { setModel({ ...model, idea: newHtml }); } }
                />
              </div>
            </div>

            <div className="form_section">
              <div className="form_group">
                <h2>Targeted Subgroup</h2>
                <div className="form_section -follow-up">
                  {
                    map(options.subgroups_list, (item, index) => {
                      const enabled = subgroupSelected(item.value);
                      return (
                        <Fragment key={ `${item.value}${index}` }>
                          <div className="form_metrics-item -compact">
                            <Radio
                              label={ item.title }
                              name={ "subgroup" }
                              value={ item.value }
                              bool={ enabled }
                              onChange={ () => { toggleSubgroup(item); } }
                            />
                          </div>
                          {
                            item.other && enabled &&
                            <div className="form-other-input">
                              <FieldHeaderWithText
                                id="project_subgroups_other"
                                errors={ errors['verizon_express_setting.subgroups_other'] }
                                initValue={ model.subgroupsOther }
                                onChange={ handleUpdateSubgroupOther }
                              />
                            </div>
                          }
                        </Fragment>
                      );
                    })
                  }
                </div>
              </div>
            </div>

            <div className="form_group">
              <h2>Email addresses to send results{ model.emails.length ? ` (${model.emails.length} added)` : ''}</h2>
              <div className="form_section -follow-up">
                <EmailsList
                  limit={ maxEmails }
                  errors={ errors['verizon_express_setting.emails'] }
                  model={ model }
                  setModel={ setModel }
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </WithSubmit>
  );
};

export default App;
