import React, { useState, useContext, useEffect } from 'react';
import { each, includes, map, filter, startsWith, uniq } from 'lodash';
import SvgIcon from "../../../../../common/components/svg-icon";
import { BaseContext, IndexData } from '../contexts';
import { vccPageEvents } from '../../../../../common/pageEventHelper';
import FiltersItemDropdown from "./filtersItemDropdown";
import FiltersItemMultiDropdown from "./filtersItemMultiDropdown";

const FiltersModal = ({ onSubmit, onClose, setFilters, filterParams }) => {
  const { baseFilters, verizonRoles } = useContext(BaseContext);
  const { createSortRow } = useContext(IndexData);
  const getSelectedOption = (name, data, option = 0) => {
    const values = (filterParams.get(name) || option).toString().split(",");
    return filter(data, (item) => (includes(values, item.value.toString())));
  };

  const getSelectedTextOptions = (name, data, option = "All") => {
    const values = (filterParams.get(name) || option).toString().split(",");
    return filter(data, (item) => (includes(values, item.label)));
  };

  const [ caseId, setCaseId ] = useState("");
  const [ conceptTitle, setConceptTitle ] = useState("");
  const [ category, setCategory ] = useState("");
  const [ audienceCategory, setAudienceCategory ] = useState([]);
  const [ action, setAction ] = useState([]);
  const [ conceptType, setConceptType ] = useState([]);
  const [ softwareProduct, setSoftwareProduct ] = useState([]);
  const [ projectLead, setProjectLead ] = useState([]);
  const [ subgroups, setSubgroups ] = useState([]);
  const [ segment, setSegment ] = useState([]);
  const [ recommendation, setRecommendation ] = useState([]);
  const [ journeyStage, setJourneyStage ] = useState([]);
  const [ includeDeviation, setIncludeDeviation ] = useState([]);
  const [ includeImage, setIncludeImage ] = useState([]);
  const [ submissionDateFrom, setSubmissionDateFrom ] = useState("");
  const [ submissionDateTo, setSubmissionDateTo ] = useState("");
  const [ customerCaseScoreFrom, setCustomerCaseScoreFrom ] = useState("");
  const [ customerCaseScoreTo, setCustomerCaseScoreTo ] = useState("");
  const [ branded, setBranded ] = useState([]);

  const wirelessSegments = [
    "Gen Pop – Wireless", "VZW Postpaid Customers", "VZW Postpaid Prospects", "VZW Prospects (Pre or Post-paid)"
  ];

  const homeInternetSegments = [
    "Gen Pop – Home Internet",
    "Fios Home Internet Customers",
    "Fios Home Internet Prospects (defined as within Fios footprint)",
    "Home Internet Prospects (reside Nationwide)",
    "VZ Home Internet Customers (Fios or FWA Customers)"
  ];

  useEffect(() => {
    setCaseId(filterParams.get('case_id'));
    setSubmissionDateFrom(filterParams.get("submission_date_from"));
    setSubmissionDateTo(filterParams.get("submission_date_to"));
    setConceptTitle(filterParams.get('concept_title'));
    setCategory(filterParams.get('category'));
    setCustomerCaseScoreFrom(filterParams.get('customer_case_score_from'));
    setCustomerCaseScoreTo(filterParams.get('customer_case_score_to'));
    setAction(getSelectedOption('action_word', baseFilters.action_word));
    setAudienceCategory(getSelectedOption('audience_category', baseFilters.audience_category));
    setConceptType(getSelectedOption('concept_type', baseFilters.concept_type));
    setSoftwareProduct(getSelectedOption('software_product', baseFilters.software_product));
    setProjectLead(getSelectedTextOptions('project_lead_email', baseFilters.project_lead_email));
    setSegment(getSelectedOption('segment', baseFilters.segment));
    setRecommendation(getSelectedOption('recommendation', baseFilters.recommendation, 3));
    setJourneyStage(getSelectedOption('journey_stage', baseFilters.journey_stage));
    setSubgroups(getSelectedOption('subgroups', baseFilters.subgroups));
    setIncludeDeviation(getSelectedOption('include_deviation', baseFilters.include_deviation));
    setIncludeImage(getSelectedOption('include_image', baseFilters.include_image));
    setBranded(getSelectedOption('branded', baseFilters.branded));
  }, []);

  useEffect(() => {
    if (includes(wirelessSegments, segment[0]?.label) && audienceCategory[0]?.value !== 1) {
      setAudienceCategory([ baseFilters.audience_category[1] ]);
    }
    if (includes(homeInternetSegments, segment[0]?.label) && audienceCategory[0]?.value !== 2) {
      setAudienceCategory([ baseFilters.audience_category[2] ]);
    }
  }, [ segment ]);

  const filterData = {
    case_id: caseId,
    concept_title: conceptTitle,
    category,
    audience_category: audienceCategory[0]?.value,
    action_word: action[0]?.value,
    concept_type: conceptType[0]?.value,
    software_product: map(softwareProduct || [], (el) => (el.value)).join(","),
    project_lead_email: map(projectLead || [], (el) => (el.label)).join(","),
    segment: segment[0]?.sub_index,
    recommendation: recommendation[0]?.value,
    journey_stage: journeyStage[0]?.value,
    subgroups: subgroups[0]?.value,
    include_deviation: includeDeviation[0]?.value,
    include_image: includeImage[0]?.value,
    submission_date_from: submissionDateFrom,
    submission_date_to: submissionDateTo,
    customer_case_score_from: customerCaseScoreFrom,
    customer_case_score_to: customerCaseScoreTo,
    branded: branded[0]?.value
  };

  const tempOptions = [
    {
      value: 1,
      label: "Item 1"
    },
    {
      value: 2,
      label: "Item 2"
    }
  ];

  const createFilters = () => {
    const filters = [];
    each(Object.entries(filterData), ([ key, value ]) => {
      if (key === "recommendation") {
        if (value !== 3) {
          filters.push(`${key}=${value}`);
        }
      } else if (!!value && value !== "0" && !(key === "project_lead_email" && value === "All")) {
        filters.push(`${key}=${value}`);
      }
    });
    return filters;
  };

  const onApply = () => {
    const filters = createFilters();
    setFilters(filters.join("&"));
    onSubmit("", [ filters.join("&"), createSortRow() ].join("&"));

    if (filters.length) {
      const eventNames = map(filters, (f) => {
        const filterName = f.split('=')[0];
        if (startsWith(filterName, 'submission_date_')) {
          return `filter_d_submission_date`;
        }
        if (startsWith(filterName, 'customer_case_score_')) {
          return `filter_d_customer_case_score`;
        }
        return `filter_d_${filterName}`;
      });

      if (verizonRoles.length) {
        vccPageEvents(uniq(eventNames), 'database');
      }
    }

    onClose(false);
  };

  const onClear = () => {
    setCaseId("");
    setConceptTitle("");
    setCategory("");
    setConceptType([ baseFilters.concept_type[0] ]);
    setSoftwareProduct([ baseFilters.software_product[0] ]);
    setProjectLead([ baseFilters.project_lead_email[0] ]);
    setAction([ baseFilters.action_word[0] ]);
    setSegment([ baseFilters.segment[0] ]);
    setRecommendation([ baseFilters.recommendation[0] ]);
    setJourneyStage([ baseFilters.journey_stage[0] ]);
    setSubmissionDateFrom("");
    setSubmissionDateTo("");
    setAudienceCategory([ baseFilters.audience_category[0] ]);
    setCustomerCaseScoreFrom("");
    setCustomerCaseScoreTo("");
    setSubgroups([ baseFilters.subgroups[0] ]);
    setIncludeDeviation([ baseFilters.include_deviation[0] ]);
    setIncludeImage([ baseFilters.include_image[0] ]);
    setBranded([ baseFilters.branded[0] ]);
  };

  const onCloseWindow = () => {
    onClose(false);
  };

  const onInputChange = (event, func) => {
    func(event.target.value);
  };

  return (
    <div className="filters-modal">
      <div className="filters-modal_plate">
        <div
          className="filters-modal_close"
          onClick={ () => {onCloseWindow();} }
        >
          <SvgIcon name="i-close-sm" className="-pointer" />
        </div>

        <div className="filters-modal_plate-top">
          <h2>Filters</h2>
        </div>
        <div className="filters-modal_plate-body">

          <div className="filters-modal_row">
            <div className="filters-modal_grid">
              <div className="filters-modal_grid-item">
                <div className="form-group">
                  <div className="form-label">
                    Case Name
                  </div>
                  <div className="form-group_field">
                    <input
                      type="text"
                      className="form-field -block"
                      value={ conceptTitle || "" }
                      onChange={ (event) => onInputChange(event, setConceptTitle) }
                    />
                  </div>
                </div>
              </div>
              <div className="filters-modal_grid-item">
                <div className="form-group">
                  <div className="form-label">
                    Case ID
                  </div>
                  <div className="form-group_field">
                    <input
                      type="text"
                      className="form-field -block"
                      value={ caseId || '' }
                      onChange={ (event) => onInputChange(event, setCaseId) }
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="filters-modal_row">
            <div className="filters-modal_grid">

              <div className="filters-modal_grid-item">
                <div className="form-group">
                  <div className="form-label">
                    Branded / Unbranded
                  </div>
                  <div className="form-group_field">
                    <FiltersItemDropdown
                      options={ baseFilters.branded }
                      selectedOptions={ branded }
                      setSelectedOptions={ setBranded }
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="filters-modal_row">
            <div className="filters-modal_grid">
              <div className="filters-modal_grid-item">
                <div className="form-group">
                  <div className="form-label">
                    Date Submitted
                  </div>
                  <div className="form-group_field -flex">
                    <div className="form-group_field-item">
                      <input
                        type="date"
                        className="form-field -block"
                        value={ submissionDateFrom || '' }
                        onChange={ (event) => onInputChange(event, setSubmissionDateFrom) }
                      />
                    </div>
                    <div className="form-group_field-item -divider" />
                    <div className="form-group_field-item">
                      <input
                        type="date"
                        className="form-field -block"
                        value={ submissionDateTo || '' }
                        onChange={ (event) => onInputChange(event, setSubmissionDateTo) }
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="filters-modal_row">
            <div className="filters-modal_grid">
              <div className="filters-modal_grid-item">
                <div className="form-group">
                  <div className="form-label">
                    Recommendation
                  </div>
                  <div className="form-group_field">
                    <FiltersItemDropdown
                      options={ baseFilters.recommendation }
                      selectedOptions={ recommendation }
                      setSelectedOptions={ setRecommendation }
                    />
                  </div>
                </div>
              </div>
              <div className="filters-modal_grid-item">
                <div className="form-group">
                  <div className="form-label">
                    CC Score
                  </div>
                  <div className="form-group_field -flex">
                    <div className="form-group_field-item">
                      <input
                        type="number"
                        className="form-field -block"
                        value={ customerCaseScoreFrom || '' }
                        onWheel={ (event) => event.target.blur() }
                        onChange={ (event) => onInputChange(event, setCustomerCaseScoreFrom) }
                      />
                    </div>
                    <div className="form-group_field-item -divider" />
                    <div className="form-group_field-item">
                      <input
                        type="number"
                        className="form-field -block"
                        value={ customerCaseScoreTo || '' }
                        onWheel={ (event) => event.target.blur() }
                        onChange={ (event) => onInputChange(event, setCustomerCaseScoreTo) }
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="filters-modal_row">
            <div className="filters-modal_grid">
              <div className="filters-modal_grid-item">
                <div className="form-group">
                  <div className="form-label">
                    Test Type
                  </div>
                  <div className="form-group_field">
                    <FiltersItemDropdown
                      key={ "audience" }
                      options={ baseFilters.audience_category }
                      selectedOptions={ audienceCategory }
                      setSelectedOptions={ setAudienceCategory }
                    />
                  </div>
                </div>
              </div>
              <div className="filters-modal_grid-item">
                <div className="form-group">
                  <div className="form-label">
                    Concept Type
                  </div>
                  <div className="form-group_field">
                    <FiltersItemDropdown
                      options={ baseFilters.concept_type }
                      selectedOptions={ conceptType }
                      setSelectedOptions={ setConceptType }
                    />
                  </div>
                </div>
              </div>
              <div className="filters-modal_grid-item">
                <div className="form-group">
                  <div className="form-label">
                    Journey Stage
                  </div>
                  <div className="form-group_field">
                    <FiltersItemDropdown
                      options={ baseFilters.journey_stage }
                      selectedOptions={ journeyStage }
                      setSelectedOptions={ setJourneyStage }
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="filters-modal_row">
            <div className="filters-modal_grid">
              <div className="filters-modal_grid-item">
                <div className="form-group">
                  <div className="form-label">
                    Sample
                  </div>
                  <div className="form-group_field">
                    <FiltersItemDropdown
                      options={ baseFilters.segment }
                      selectedOptions={ segment }
                      setSelectedOptions={ setSegment }
                      maxMenuHeight={ 240 }
                    />
                  </div>
                </div>
              </div>
              <div className="filters-modal_grid-item">
                <div className="form-group">
                  <div className="form-label">
                    Targeted Subgroup
                  </div>
                  <div className="form-group_field">
                    <FiltersItemDropdown
                      options={ baseFilters.subgroups }
                      selectedOptions={ subgroups }
                      setSelectedOptions={ setSubgroups }
                      maxMenuHeight={ 240 }
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="filters-modal_row">
            <div className="filters-modal_grid">
              <div className="filters-modal_grid-item">
                <div className="form-group">
                  <div className="form-label">
                    Software Product Type
                  </div>
                  <div className="form-group_field">
                    <FiltersItemMultiDropdown
                      options={ baseFilters.software_product }
                      selectedOptions={ softwareProduct }
                      setSelectedOptions={ setSoftwareProduct }
                      maxMenuHeight={ 165 }
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="filters-modal_row">
            <div className="filters-modal_grid">
              <div className="filters-modal_grid-item">
                <div className="form-group">
                  <div className="form-label">
                    Project Lead
                  </div>
                  <div className="form-group_field">
                    <FiltersItemMultiDropdown
                      options={ baseFilters.project_lead_email }
                      selectedOptions={ projectLead }
                      setSelectedOptions={ setProjectLead }
                      maxMenuHeight={ 165 }
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="filters-modal_row">
            <div className="filters-modal_grid">
              <div className="filters-modal_grid-item">
                <div className="form-group">
                  <div className="form-label">
                    Includes Unhappy Path
                  </div>
                  <div className="form-group_field">
                    <FiltersItemDropdown
                      options={ baseFilters.include_deviation }
                      selectedOptions={ includeDeviation }
                      setSelectedOptions={ setIncludeDeviation }
                      menuPlacement="top"
                      maxMenuHeight={ 200 }
                    />
                  </div>
                </div>
              </div>
              <div className="filters-modal_grid-item">
                <div className="form-group">
                  <div className="form-label">
                    Includes Image
                  </div>
                  <div className="form-group_field">
                    <FiltersItemDropdown
                      options={ baseFilters.include_image }
                      selectedOptions={ includeImage }
                      setSelectedOptions={ setIncludeImage }
                      menuPlacement="top"
                      maxMenuHeight={ 200 }
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="filters-modal_row">
            <div className="filters-modal_grid">
              <div className="filters-modal_grid-item">
                <div className="form-group">
                  <div className="form-label">
                    Tags
                  </div>
                  <div className="form-group_field">
                    <FiltersItemDropdown
                      options={ tempOptions }
                      selectedOptions={ [] }
                      menuPlacement="top"
                      maxMenuHeight={ 200 }
                      disabled
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="filters-modal_plate-bottom">
          <div className="filters-modal_grid -right-align">
            <div className="filters-modal_grid-item -auto">
              <button className="button -bordered-white" onClick={ onClear } >Clear</button>
            </div>
            <div className="filters-modal_grid-item -auto">
              <button className="button" onClick={ onApply }>Apply Filters</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FiltersModal;
